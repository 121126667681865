/* eslint-env browser */
import 'normalize.css';

const onClientEntry = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'button_click',
    event_action: 'Signup',
  });
};

export default { onClientEntry };
