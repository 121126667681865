// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-question-jsx": () => import("./../src/templates/question.jsx" /* webpackChunkName: "component---src-templates-question-jsx" */),
  "component---src-pages-debug-summary-jsx": () => import("./../src/pages/debug-summary.jsx" /* webpackChunkName: "component---src-pages-debug-summary-jsx" */),
  "component---src-pages-debug-jsx": () => import("./../src/pages/debug.jsx" /* webpackChunkName: "component---src-pages-debug-jsx" */),
  "component---src-pages-disciplin-ambition-jsx": () => import("./../src/pages/disciplin/ambition.jsx" /* webpackChunkName: "component---src-pages-disciplin-ambition-jsx" */),
  "component---src-pages-disciplin-ansvarlighed-jsx": () => import("./../src/pages/disciplin/ansvarlighed.jsx" /* webpackChunkName: "component---src-pages-disciplin-ansvarlighed-jsx" */),
  "component---src-pages-disciplin-integritet-jsx": () => import("./../src/pages/disciplin/integritet.jsx" /* webpackChunkName: "component---src-pages-disciplin-integritet-jsx" */),
  "component---src-pages-disciplin-udveksling-jsx": () => import("./../src/pages/disciplin/udveksling.jsx" /* webpackChunkName: "component---src-pages-disciplin-udveksling-jsx" */),
  "component---src-pages-en-about-jsx": () => import("./../src/pages/en/about.jsx" /* webpackChunkName: "component---src-pages-en-about-jsx" */),
  "component---src-pages-en-discipline-ambition-jsx": () => import("./../src/pages/en/discipline/ambition.jsx" /* webpackChunkName: "component---src-pages-en-discipline-ambition-jsx" */),
  "component---src-pages-en-discipline-exchange-jsx": () => import("./../src/pages/en/discipline/exchange.jsx" /* webpackChunkName: "component---src-pages-en-discipline-exchange-jsx" */),
  "component---src-pages-en-discipline-integrity-jsx": () => import("./../src/pages/en/discipline/integrity.jsx" /* webpackChunkName: "component---src-pages-en-discipline-integrity-jsx" */),
  "component---src-pages-en-discipline-responsibility-jsx": () => import("./../src/pages/en/discipline/responsibility.jsx" /* webpackChunkName: "component---src-pages-en-discipline-responsibility-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-result-jsx": () => import("./../src/pages/en/result.jsx" /* webpackChunkName: "component---src-pages-en-result-jsx" */),
  "component---src-pages-en-thank-you-jsx": () => import("./../src/pages/en/thank-you.jsx" /* webpackChunkName: "component---src-pages-en-thank-you-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-om-jsx": () => import("./../src/pages/om.jsx" /* webpackChunkName: "component---src-pages-om-jsx" */),
  "component---src-pages-resultat-jsx": () => import("./../src/pages/resultat.jsx" /* webpackChunkName: "component---src-pages-resultat-jsx" */),
  "component---src-pages-tak-jsx": () => import("./../src/pages/tak.jsx" /* webpackChunkName: "component---src-pages-tak-jsx" */)
}

